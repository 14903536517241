<template>
  <div class="container">
    <div class="operation mt20">
      <div class="left">
        <el-button type="primary" :disabled="listLoading" @click="handleOpen">添加盒子</el-button>
      </div>
      <div>
        <el-dropdown @command="handleCommand" trigger="click">
          <el-button type="primary">
            批量操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">设置营业时间上传速度上限</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div>
      <el-table
        style="width: 100%"
        v-loading="listLoading"
        :data="tableData"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="45"></el-table-column>
        <template v-for="(col, index) in columns">
          <el-table-column :key="index" :min-width="col.width" :align="col.align" :prop="col.prop" :label="col.label" :sortable="col.sortable">
            <template slot="header" slot-scope="scope">
              <template v-if="col.prop === 'opentimeMaxSpeed'">
                <span>{{ col.label }}</span>
                <el-tooltip effect="light" content="营业时间上传速度上限">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </template>
              <template v-else>{{ col.label }}</template>
            </template>
            <template slot-scope="scope">
              <div v-if="col.prop === 'boxNo'">
                <span>{{ scope.row[col.prop] }}</span>
              </div>
              <div v-else-if="col.prop === 'status'"><status :data="scope.row.status" /></div>
              <div v-else-if="col.prop === 'boxSubType'">
                <sysType ref="sysType" :data="scope.row" :key="scope.row.boxId" @update="fetchList" :canEdit="true" />
              </div>
              <div v-else-if="col.prop === 'opentimeMaxSpeed'">
                {{ formatSpeedLimit(scope.row.opentimeMaxSpeed) }}
              </div>
              <div v-else>{{ isEmpty(scope.row[col.prop]) ? '--' : scope.row[col.prop] }}</div>
            </template>

            <!-- <template slot-scope="scope">
              <div v-if="col.prop === 'boxSubType'"></div>
            </template> -->
          </el-table-column>
        </template>
        <el-table-column fixed="right" label="操作" width="60" align="center">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="(command) => handleSettings(command, scope.row)">
              <el-button type="text"><i class="el-icon-more"></i></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">摄像头管理</el-dropdown-item>
                <el-dropdown-item v-if="showNvr" command="4">NVR管理</el-dropdown-item>
                <el-dropdown-item command="3">设备信息查询</el-dropdown-item>
                <el-dropdown-item command="0">设置上传上限</el-dropdown-item>
                <el-dropdown-item command="5">运行系统</el-dropdown-item>
                <el-dropdown-item command="2">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        :currentPage="pageOptions.pageNumber"
        :total="total"
        :pageSize="pageOptions.pageSize"
        @size="handleSizeChange"
        @current="handleCurrentChange"
      ></pagination>
    </div>
    <dialog-add-box :dialogVisible="dialogVisible" :hasSolution="true" @confirm="handleConfirm" @close="handleClose" />
    <network-speed-limit
      :isBatch="isBatch"
      :data="selectedRows"
      :dialogVisible="settingsDialogVisible"
      @confirm="handleSettingsConfirm"
      @close="handleSettingsClose"
    ></network-speed-limit>
  </div>
</template>

  <script>
import Pagination from 'src/components/Pagination'
import { boxColumns } from './common'
import { solution } from 'src/api/product'
import { getNewestToken } from 'src/api/legacy'
import { generateTableData, formatSpeedLimit } from 'src/utils/common'
import { isEmpty } from 'src/utils/method'
import DialogAddBox from 'src/components/DialogAddBox'
import Status from 'src/components/Status'
import SysType from 'src/components/SysType/index.vue'
import NetworkSpeedLimit from '../components/NetworkSpeedLimit.vue'

export default {
  components: {
    Pagination,
    DialogAddBox,
    Status,
    SysType,
    NetworkSpeedLimit,
  },
  data() {
    const { productId } = this.$route.query
    return {
      pageOptions: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      listLoading: true,
      columns: boxColumns,
      productId,
      tableData: [],
      dataSource: [],

      prop: '',
      order: '',

      dialogVisible: false,
      settingsDialogVisible: false,
      selectedRows: [],
      isBatch: false,
      multipleSelection: [],
    }
  },
  watch: {
    pageOptions: {
      handler() {
        this.formatTableData()
      },
      deep: true,
    },
  },
  created() {
    this.handleListQuery()
  },
  computed: {
    showNvr() {
      return this.$route.name === 'retail-traffic-device-index'
    }
  },
  methods: {
    isEmpty,
    formatSpeedLimit,
    handleOpen() {
      this.dialogVisible = true
    },
    handleConfirm(item) {
      if (item) {
        this.fetchList()
        this.dialogVisible = false
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    formatTableData() {
      this.tableData = generateTableData(this.dataSource, { order: this.order, prop: this.prop }, this.pageOptions)
    },
    handleDelete(row) {
      this.$msgbox({
        message: `盒子（MAC/端口号：${row.macId}/${row.boxPort}）已绑定${row.cameraCount}个摄像头，确认删除？`,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            const { status } = await solution.removeBox({
              boxId: row.boxId,
              productId: row.productId,
            })
            if (status === 0) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.fetchList()
            }
            instance.confirmButtonLoading = false
          }
          done()
        },
      })
    },
    toCameraManage(row) {
      let name = ''
      const path = this.$route.path
      if (path.startsWith('/productManage/retailTraffic')) {
        name = 'retail-traffic-device-cameraManage'
      } else if (path.startsWith('/productManage/security')) {
        name = 'security-device-cameraManage'
      }

      this.$router.push({
        name,
        query: {
          shopId: row.shopId,
          productId: row.productId,
          boxId: row.boxId,
          boxType: row.boxType,
        },
      })
    },
    handleListQuery() {
      this.pageOptions.pageNumber = 1
      this.queryList()
    },
    formatQueryData() {
      return {
        productId: this.productId,
      }
    },
    async fetchList() {
      this.listLoading = true
      const params = this.formatQueryData()
      try {
        const res = await solution.getBoxByProductId(params)
        this.dataSource = res.data || []
        this.total = res.data?.length || 0
        this.formatTableData()
      } finally {
        this.listLoading = false
      }
    },
    async toDeviceInfo(row) {
      const { data: accessToken } = await getNewestToken({})

      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      // const accessToken = localStorage.getItem('accessToken')
      window.open(
        href +
          '?accessToken=' +
          accessToken +
          '&boxId=' +
          row.boxId +
          '&username=' +
          username +
          '&boxType=' +
          row.boxType
      )
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
      this.formatTableData()
    },
    queryList() {
      this.fetchList(this.formatQueryData(this.listQuery))
    },
    handleSizeChange(val) {
      this.pageOptions.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.pageOptions.pageNumber = val
      this.queryList()
    },
    handleOpenSettings(row) {
      this.selectedRows = [row]
      this.isBatch = false
      this.settingsDialogVisible = true
    },
    handleSettingsConfirm() {
      this.fetchList()
      this.settingsDialogVisible = false
      this.selectedRows = []
      this.multipleSelection.length = 0
    },
    handleSettingsClose() {
      this.selectedRows = []
      this.settingsDialogVisible = false
    },
    handleCommand(command) {
      if (command === '0') {
        if (!this.multipleSelection.length) {
          this.$message.warning('请先选择盒子')
          return
        }
        this.isBatch = true
        this.settingsDialogVisible = true
        this.selectedRows = [...this.multipleSelection]
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    goRoute(row, name){
      this.$router.push({
        name,
        query: {
          shopId: row.shopId,
          productId: row.productId,
          boxId: row.boxId,
          boxType: row.boxType,
        },
      })
    },
    handleSettings(command, row) {
      if (command === '0') {
        this.handleOpenSettings(row)
        return
      }
      if (command === '1') {
        this.toCameraManage(row)
        return
      }
      if (command === '2') {
        this.handleDelete(row)
        return
      }
      if (command === '3') {
        this.toDeviceInfo(row)
        return
      }
      if (command === '4') {
        this.goRoute(row, 'retail-traffic-device-nvrManage')
        return
      }
      if (command === '5') {
        const index = this.tableData.indexOf(row)
        // TODO
        this.$refs.sysType[index].handleOpen()
        return
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 12px;
  .operation {
    display: flex;
    justify-content: space-between;
    // padding-top: 20px;
  }
}
</style>