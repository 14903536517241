// 业务程序
export const businessList = [
  {
    label: 'Rid进出店',
    value: 'RID',
  },
  {
    label: '区域进出店',
    value: 'AREA_IN_OUT_SHOP',
  },
  {
    label: '店前客流',
    value: 'PASS_BY',
  },
  {
    label: '驻足客流',
    value: 'STAY_CUSTOMER_FLOW',
  },
  {
    label: '热力图',
    value: 'HEAT_MAP',
  },
  {
    label: '客流流向',
    value: 'CUSTOMER_FLOW_DIR',
  },
  {
    label: '夜间防盗',
    value: 'NIGHT_ANTI_THEFT',
  },
  {
    label: '收银事件',
    value: 'CASHIER_EVENT',
  },
  {
    label: '进出店店员排除',
    value: 'CLERK_DEDUPLICATION',
  },
  {
    label: '全店店员排除',
    value: 'EXCLUDE_STAFF',
  },
  {
    label: '小孩去重',
    value: 'CHILD_DEDUPLICATION',
  },
  {
    label: '平均停留时长',
    value: 'AVG_STAY_DURATION',
  },
]

// 区域配置
export const areaList = [
  {
    label: '进店线',
    value: 'ENTER_SHOP_ROUTE',
  },
  {
    label: '进出店区域',
    value: 'IN_OUT_AREA',
  },
  {
    label: 'Passby区域',
    value: 'PASSBY_AREA',
  },
  {
    label: '驻足区域',
    value: 'STAY_AREA',
  },
  {
    label: '热力区域',
    value: 'HOT_AREA',
  },
  {
    label: '流向区域',
    value: 'FLOW_AREA',
  },
  {
    label: '监控防盗区域',
    value: 'MONITOR_AND_ANTI_THEFT',
  },
  {
    label: '收银区域',
    value: 'CASHIER_AREA',
  },
  {
    label: '店内排重区',
    value: 'INNER_SHOP_DEDUP_AREA',
  },
]

export const boxColumns = [
  {
    prop: 'boxNo',
    label: '盒子编号',
    width: 100,
    sortable: true,
  },
  {
    prop: 'status',
    label: '状态',
    width: 70,
    sortable: false,
  },
  {
    prop: 'macId',
    label: '盒子Mac',
    width: 120,
    sortable: true,
  },
  {
    prop: 'boxPort',
    label: '端口号',
    align: 'right',
    width: 100,
    sortable: true,
  },
  {
    prop: 'boxTypeStr',
    label: '盒子类型',
    width: 100,
    sortable: true,
  },
  {
    prop: 'boxSubType',
    label: '运行系统',
    width: 100,
  },
  {
    prop: 'boxModel',
    label: '型号',
    sortable: true,
  },
  {
    prop: 'opentimeMaxSpeed',
    label: '上传限速(K)',
    align: 'right',
    width: 100,
    sortable: false,
  },
  {
    prop: 'cameraCount',
    label: '摄像头数量',
    align: 'right',
    width: 110,
    sortable: true,
  },
  {
    prop: 'prodVer',
    label: '产品版本',
    width: 100,
  },
  {
    prop: 'sysVer',
    label: '系统版本',
    width: 120,
  },
  {
    prop: 'auroraVer',
    label: 'Aurora版本',
    width: 130,
  },
  {
    prop: 'tsUploadVer',
    label: 'TsUpload版本',
    width: 120,
  },
  {
    prop: 'safetyVer',
    label: '安防版本',
    width: 100,
  },
]
